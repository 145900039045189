import React, { useState, useContext, useEffect, useCallback } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import AdminTableExportButton from "components/AdminTable/AdminTableExportButton"
import FilterCompany from "components/Filter/FilterCompany"
import ErrorUtils from "components/Tools/ErrorUtils"
import Hub from "views/Hub/Hub"
import HubExportConfig from "views/Hub/HubExportConfig"
import UserUtils from "components/Utils/UserUtils"
import { fetchWithToken } from "components/Fetch/api-fetch"

// core components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Tooltip from "@material-ui/core/Tooltip"
import VisibilityIcon from "@material-ui/icons/Visibility"
import _ from "lodash"

// Nomi dei campi delle colonne
function createData(
  id,
  description,
  clientId,
  clientSecret,
  lab,
  active,

) {
  return {
    id,
    description,
    clientId,
    clientSecret,
    lab,
    active,

  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows.map((elem) => {
    let newElem = elem
    newElem.viewPassword = false
    return newElem
  })
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.hubId !== rowToDelete
  })
}

// Click View Password
const handleClickViewPassword = (rows, setRows, rowToViewPassword) => {
  setRows(
    rows.map((elem) => {
      let newElem = elem
      if (elem === rowToViewPassword) {
        newElem.viewPassword = !newElem.viewPassword
      }
      return newElem
    })
  )
}

export default function Hubs() {
  const {
    t,
    isRetailerUser,
    getRetailerId,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  const [isRetailer] = useState(isRetailerUser())
  const [retailerId] = useState(getRetailerId())
  const [allBrandsDevices, setAllBrandsDevices] = useState([])
  const [allScopes, setAllScopes] = useState([])
  const [changeCompany, setChangeCompany] = useState()
  const [companyData, setCompanyData] = useState()
  const [companyName, setCompanyName] = useState()
  const [companyScopes, setCompanyScopes] = useState()
  const [companyId, setCompanyId] = useState()
  const [connectName, setConnectName] = useState()
  const [dropboxLinkToShow, setDropboxLinkToShow] = useState()
  const dropboxLuneauConnect = "https://www.dropbox.com/scl/fi/91w2jve5z9pr9wo4f9uwg/MiniHub.msi?rlkey=x33ov9qd8f8p7b4cqqvsmfml6&st=2n718zik&dl=0"
  const dropboxPegasoConnect = "https://www.dropbox.com/scl/fi/91w2jve5z9pr9wo4f9uwg/MiniHub.msi?rlkey=x33ov9qd8f8p7b4cqqvsmfml6&st=2n718zik&dl=0"
  const [filter] = useState([
    {
      id: "hubId",
      value: "",
      label: t("10147"),
    },
    {
      id: "description",
      value: "",
      label: t("10110"),
    },
    {
      id: "clientId",
      value: "",
      label: t("10148"),
    },
    {
      id: "labId",
      value: "",
      label: t("10141"),
    },
    {
      id: "labName",
      value: "",
      label: t("10146"),
    }
  ])

  const headCells = [
    {
      id: "hubId",
      numeric: false,
      disablePadding: false,
      label: t("10147").toUpperCase(),
    },
    {
      id: "descriptionView",
      numeric: false,
      disablePadding: false,
      label: t("10110").toUpperCase(),
    },
    {
      id: "clientId",
      numeric: false,
      disablePadding: false,
      label: t("10148").toUpperCase(),
    },
    {
      id: "password",
      numeric: false,
      disablePadding: false,
      label: t("10063").toUpperCase(),
    },
    {
      id: "labView",
      numeric: false,
      disablePadding: false,
      label: t("10146").toUpperCase(),
    },
    { id: "active", numeric: false, disablePadding: false, label: t("10047").toUpperCase() },
  ]
  const orderByDefault = "hubId"
  //const [labPhone, setLabPhone] = useState("3333")
  const [urlAccessCode, setUrlAccessCode] = useState()
  const [retailerCompany, setRetailerCompany] = useState()
  const urlGet = `${apiUrl}/companies/${urlAccessCode}/list-hubs`
  const urlDelete = `${apiUrl}/companies/${urlAccessCode}/hubs/`


  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.hubId
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 6
  }

  // Finestra per esportazione Config
  function DialogExportConfig(props) {
    const { openExportConfig, handleClickClose } = props
    return (
      openExportConfig ? (
        <Dialog
          onClose={handleClickClose}
          aria-labelledby="customized-dialog-title"
          open={openExportConfig}
          maxWidth="xl"
        >
          <DialogContent>
            <HubExportConfig
              {...props}
            />
          </DialogContent>
        </Dialog>
      ) : (
        null
      )

    )
  }

  //Tasto Export Config Hub 
  function CellButtonExportConfig(props) {
    const { keyToUse, hubId } = props
    const [openExportConfig, setOpenExportConfig] = useState(false)

    // Click Routing Job
    function handleOpenExportConfig(event) {
      setOpenExportConfig(true)
    }

    // Click Close Job
    function handleCloseExportConfig() {
      setOpenExportConfig(false)
    }

    return (
      <TableCell key={"tableBodyCellExportConfig-" + keyToUse} align="left">
        <AdminTableExportButton
          deleteButton={keyToUse}
          handleClickOpen={handleOpenExportConfig}
        />
        {openExportConfig ?
          (<DialogExportConfig
            openExportConfig={openExportConfig}
            handleClickClose={handleCloseExportConfig}
            hubId={hubId}
            urlAccessCode={urlAccessCode}>

          </DialogExportConfig>
          ) : (
            null)
        }
      </TableCell>
    )
  }

  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonDelete,
      CellButtonEdit,
      keyToUse,
      labelId,
      row,
      rows,
      setRows,
    } = props
    const key = keyToUse

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {UserUtils.formatDescription(row)}
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {row.clientId}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {row.clientSecret
            ? row.viewPassword
              ? row.clientSecret
              : "*".repeat(row.clientSecret.length)
            : ""}
          <Tooltip
            title={row.viewPassword ? t("10112") : t("10113")}
          >
            <IconButton
              aria-label={
                row.viewPassword ? t("10112") : t("10113")
              }
              variant="outlined"
              onClick={() => handleClickViewPassword(rows, setRows, row)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {UserUtils.formatLab(row)}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        {!isRetailer ? <CellButtonDelete keyToUse={keyToUse} /> : null}
        <CellButtonExportConfig keyToUse={keyToUse} hubId={getAdminTableKey(row)} />
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(row, row.hubId,
            filter.filter((filter) => { return filter.id === "hubId" })[0].value)
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatDescription(row),
            filter.filter((filter) => { return filter.id === "description" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.clientId,
            filter.filter((filter) => { return filter.id === "clientId" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatLab(row),
            filter.filter((filter) => { return filter.id === "labId" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatLab(row),
            filter.filter((filter) => { return filter.id === "labName" })[0].value
          )
        })
      : rows
  }

  /*************************************
   *
   * Fetch
   *
   *************************************/
  // Fetch - Filtra brands x RetailerBrands
  const fetchRetailerBrands = useCallback((brands) => {
    let newBrands = []
    if (retailerId) {
      const url = `${apiUrl}/retailer/${retailerId}`
      fetchWithToken(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body
        })
        .then(({ availableBrands }) => {
          if (availableBrands) {
            if (availableBrands.length > 0) {
              // Filtro i brand con solo quelli disponibili per il retailer
              availableBrands.map(elem => {
                let availableBrandId = elem.brandId
                brands.map(elem2 => {
                  let brandId = elem2.brandId
                  if (brandId === availableBrandId) {
                    newBrands.push(elem2)
                  }
                  return elem2
                })
                return elem
              })
            }
          }
          setAllBrandsDevices(newBrands)
        })
        .catch((err) => {
          setAllBrandsDevices(newBrands)
          ErrorUtils.errorLog("FetchRetailerBrands  Error", err)
        })
    } else {
      setAllBrandsDevices(newBrands)
    }
  }, [apiUrl, retailerId, refresh_token, access_token, setAccess_token])

  //Fetch -  Elenco devices
  const fetchDevices = useCallback(() => {
    const url = `${apiUrl}/brands?excludeSoftware=true`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ brands }) => {
        if (isRetailer) {
          fetchRetailerBrands(brands)
        } else {
          setAllBrandsDevices(brands)
        }
      })
      .catch((err) => {
        ErrorUtils.errorLog("FetchDevices  Error", err)
      })
  }, [apiUrl, fetchRetailerBrands, isRetailer, refresh_token, access_token, setAccess_token])

  function arrayUnique(arr) {
    var newArr = []
    arr.forEach(function (itm) {
      var unique = true;
      newArr.forEach(function (itm2) {
        if (_.isEqual(itm, itm2)) unique = false;
      });
      if (unique) newArr.push(itm);
    });
    return newArr;
  }

  //Fetch -  Elenco scopes
  const fetchScopes = useCallback(() => {
    const url = `${apiUrl}/scopetypes`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((scope) => {
        var scopeTypes = scope.scopeTypes
        var scopeLab = []
        companyScopes.map((elem) =>
          scopeTypes.map((option) =>
            option.scopes.map((last) => { return (elem.scopeId === last.scopeId && option.software === "minihub") ? scopeLab.push(option) : null })
          )
        )
        scopeLab = arrayUnique(scopeLab)
        setAllScopes(scopeLab)
      })
      .catch((err) => {

        ErrorUtils.errorLog("FetchDevices  Error", err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token, companyScopes])

  //Fetch -  Dati laboratorio per numero di telefono  e dati hub per modifica 
  const fetchCompanyData = useCallback(
    (setActionFetch) => {
      const urlCompany = `${apiUrl}/companies/${urlAccessCode}`
      //let labId
      fetchWithToken(urlCompany, {
        method: "GET",
        apiUrl: apiUrl,
        urlAccessCode: urlAccessCode,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          setCompanyData(data)
          setActionFetch(false)
        })
        .catch((err) => {
          ErrorUtils.errorLog("FetchLabId  Error", err)
        })
    }, [access_token, apiUrl, refresh_token, setAccess_token, urlAccessCode]
  )

  //Fetch -  Dati laboratorio per numero di telefono  e dati hub per modifica 
  const fetchHubId = useCallback(
    (hubId, rows, setData, setActionFetch) => {
      const urlHub = `${apiUrl}/companies/${urlAccessCode}/hubs/${hubId}`
      //let labId
      fetchWithToken(urlHub, {
        method: "GET",
        apiUrl: apiUrl,
        urlAccessCode: urlAccessCode,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          setData(data)
          //labId = data.lab.labId
          fetchCompanyData(setActionFetch)
        })
        .catch((err) => {
          ErrorUtils.errorLog("FetchLabId  Error", err)
        })
    }

    //DECOMENTARE SE SI VUOLE INSERIRE IL NUMERO DI TELEFONO DEL LAB
      /*let appHub
      let hubToFetch

      hubToFetch = rows.filter(function (elem) {
        return elem.hubId === hubId
      })[0]
      labId = hubToFetch.lab.labId
      appHub = hubToFetch*/
      /*const urlLab = `${apiUrl}/companies/${urlAccessCode}/labs/${labId}`

      fetchWithToken(urlLab, {
        method: "GET",
        apiUrl: apiUrl,
        urlAccessCode: urlAccessCode,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      })
        .then((response) => {
          return response.body // data
        })
        .then((data) => {
          setLabPhone(data.phone)
         // setData(appHub)
          setActionFetch(false)
        })
        .catch((err) => {
          ErrorUtils.errorLog("FetchLabId  Error", err)
        })
      }*/,
    [access_token, apiUrl, fetchCompanyData, refresh_token, setAccess_token, urlAccessCode]
  )

  const changeDropBoxLink = useCallback(() => {
    switch (retailerCompany) {
      case 1:
        setDropboxLinkToShow(dropboxPegasoConnect)
        setConnectName("Pegaso connect")
        break
      case 2:
      case 5:
        setDropboxLinkToShow(dropboxLuneauConnect)
        setConnectName("Visionix connect")
        break
      default:
        setDropboxLinkToShow(dropboxPegasoConnect)
        setConnectName("Pegaso connect")

        break
    }
  }, [retailerCompany])

  /*************************************
   *
   * UseEffect
   *
   *************************************/
  useEffect(() => {
    fetchDevices()
    if (changeCompany) {
      fetchScopes()
      changeDropBoxLink()
    }
  }, [fetchDevices, fetchScopes, changeDropBoxLink, changeCompany, setChangeCompany, urlAccessCode, allScopes])

  return (
    <div>
      <FilterCompany
        urlAccessCode={urlAccessCode}
        setUrlAccessCode={setUrlAccessCode}
        companyName={companyName}
        companyId={companyId}
        setCompanyName={setCompanyName}
        setCompanyId={setCompanyId}
        setChangeCompany={setChangeCompany}
        setCompanyScopes={setCompanyScopes}
        setRetailerCompany={setRetailerCompany}
      />
      {urlAccessCode ? (
        <React.Fragment>
          <Card>
            <CardBody>
              {t("10279")}
              <Link
                to="/about"
                // href="#"
                onClick={() => {
                  window.open(dropboxLinkToShow, "_blank")
                }}
                variant="body2"
              > {connectName}</Link>
            </CardBody>
          </Card>
          <AdminTable
            AddEditElement={Hub}
            AdminTableCells={AdminTableCells}
            allBrandsDevices={allBrandsDevices}
            allScopes={allScopes}
            changeCompany={changeCompany}
            companyId={companyId}
            companyData={companyData}
            companyName={companyName}
            createData={createData}
            defaultRowsPerPage={10}
            disableAdd={isRetailer}
            filter={filter}
            FilterAdminTableCells={FilterAdminTableCells}
            getAdminTableKey={getAdminTableKey}
            getAdminTableCellsCount={getAdminTableCellsCount}
            handleClickOpenAction={fetchHubId}
            handleClickViewPassword={handleClickViewPassword}
            headCells={headCells}
            isFullTable={true}
            isHub={true}
            orderByDefault={orderByDefault}
            //phone={labPhone}
            remapDeleteRow={remapDeleteRow}
            remapGetRows={remapGetRows}
            setChangeCompany={setChangeCompany}
            urlAccessCode={urlAccessCode}
            urlDelete={urlDelete}
            urlGet={urlGet}
            useCompany={true}
          />
        </React.Fragment>
      ) : null}
    </div>
  )
}
