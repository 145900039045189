const it = {
  translation: {
    1    : "nessun risultato trovato con questi filtri",
    2    : "il diametro deve essere maggiore di 1",
    3    : "il diametro ridotto deve essere numerico",
    4    : "il valore della sfera deve essere numerico",
    5    : "il valore della sfera non è corretto",
    6    : "valore della sfera fuori limite (-30 / +30)",
    7    : "il valore del cilindro deve essere numerico",
    8    : "il valore del cilindro non è corretto",
    9    : "valore del cilindro fuori limite (-10 / +10)",
    10   : "il valore dell' asse deve essere numerico",
    11   : "il valore dell' asse deve essere positivo",
    12   : "il valore dell'asse deve essere inferiore a 360",
    13   : "il valore dell'asse deve essere un numero intero",
    14   : "il valore deve essere numerico",
    15   : "il valore deve essere positivo",
    16   : "il valore deve essere maggiore di",
    17   : "il valore deve essere inferiore a",
    18   : "il valore deve essere compreso tra -10 e 10",
    19   : "il diametro deve essere minore di 99",
    20   : "operazione fallita",
    21   : "il valore deve essere un numero intero",
    22   : "errore nel salvataggio",
    23   : "fetch lab id error",
    24   : "impossibile aggiungere una nuova route (nessun altro fornitore disponibile)",
    25   : "fetch devices error",
    26   : "fetch optician error",
    27   : "fetch optician id error",
    28   : "salvataggio utente con errori",
    29   : "Crea utente demoOptician",   
    30   : "Crea utente demoLab",     
    31   : "fetch retailers error", 
    32   : "nessuna licenza assegnata", 
    5000 : "aspherica",
    5001 : "torica",
    5002 : "bifocale",
    5003 : "curve top",
    5004 : "doppio segmento",
    5005 : "e-line multifocale",
    5006 : "flat top",
    5007 : "lenticolare",
    5008 : "progressive",
    5009 : "quadrifocale",
    5010 : "round",
    5011 : "monofocale",
    5012 : "trifocale",
    10000: "filtri",
    10001: "aggiungi nuovo",
    10002: "aggiorna lista",
    10003: "attenzione",
    10004: "conferma eliminazione",
    10005: "annulla",
    10006: "conferma",
    10007: "elimina",
    10008: "modifica",
    10009: "premi per chiudere",
    10010: "salva",
    10011: "aggiungi logo azienda",
    10012: "cerca",
    10013: "impostazioni protocollo avanzate",
    10014: "opzioni download",
    10015: "opzioni upload",
    10016: "salva modifiche",
    10017: "impostazioni seriale avanzate",
    10018: "ip protocol",
    10019: "job without barcode",
    10020: "nack",
    10021: "offset metal",
    10022: "offset plastic",
    10023: "single side",
    10024: "tracer id",
    10025: "without barcode",
    10026: "req",
    10027: "trcfmtbin",
    10028: "trcfmtpoints",
    10029: "zfmtbin",
    10030: "zfmtpoints",
    10031: "selezionato",
    10032: "timeout",
    10033: "handshake",
    10034: "readBufferSize",
    10035: "writeBufferSize",
    10036: "readTimeout",
    10037: "writeTimeout",
    10038: "rts",
    10039: "dtr",
    10040: "discardNull",
    10041: "aziende",
    10042: "seleziona azienda",
    10043: "dispositivo",
    10044: "marche",
    10045: "seleziona marca",
    10046: "nome dispositivo",
    10047: "attivo",
    10048: "seriale",
    10049: "lan (tcp/ip)",
    10050: "porta di comunicazione",
    10051: "inserire solo il numero della porta",
    10052: "velocità porta",
    10053: "bit di parità",
    10054: "bit di dati",
    10055: "bit di stop",
    10056: "indirizzo ip (PC)",
    10057: "porta ip",
    10058: "scope",
    10059: "offset other",
    10060: "seleziona scope",
    10061: "supporto remoto",
    10062: "username",
    10063: "password",
    10064: "login",
    10065: "utente",
    10066: "profilo",
    10067: "logout",
    10068: "scope data",
    10069: "sistema",
    10070: "seleziona sistema",
    10071: "percorso",
    10072: "topic id",
    10073: "file name type",
    10074: "estensione",
    10075: "include subdirectories",
    10076: "internal buffer size",
    10077: "porta di comunicazione",
    10078: "immettere solo il numero della porta",
    10079: "nome",
    10080: "inglese",
    10081: "italiano",
    10082: "nome stampante",
    10083: "lingua",
    10084: "barcode",
    10085: "nessuno",
    10086: "pari",
    10087: "dispari",
    10088: "bisello",
    10089: "nylor",
    10090: "glasant",
    10091: "automatico",
    10092: "bevp2",
    10093: "50/50",
    10094: "bevp5",
    10095: "bevp1",
    10096: "si",
    10097: "no",
    10098: "plastica",
    10099: "metallo",
    10100: "glasant",
    10101: "optyl",
    10102: "utente salvato",
    10103: "utente amministratore",
    10104: "dati",
    10105: "solo lettura",
    10106: "seleziona lingua",
    10107: "seleziona utc",
    10108: "email",
    10109: "demo",
    10110: "descrizione",
    10111: "utc",
    10112: "nascondi password",
    10113: "mostra password",
    10114: "id azienda",
    10115: "jobd azienda",
    10116: "url access code",
    10117: "nome azienda",
    10118: "url azienda",
    10119: "azienda salvata",
    10120: "dati azienda",
    10121: "via",
    10122: "numero civico",
    10123: "città",
    10124: "provincia",
    10125: "stato",
    10126: "codice postale",
    10127: "partita iva",
    10128: "email",
    10129: "riferimento",
    10130: "ico",
    10131: "png",
    10132: "logo salvato",
    10133: "logo azienda",
    10134: "estensione logo",
    10135: "id dispositivo",
    10136: "id modello",
    10137: "modello",
    10138: "marca",
    10139: "tipo",
    10140: "connessione",
    10141: "id laboratorio",
    10142: "non esiste",
    10143: "hub salvato",
    10144: "dati hub utente",
    10145: "azienda",
    10146: "laboratorio",
    10147: "hub id",
    10148: "id cliente",
    10149: "telefono",
    10150: "instradamento job ai fornitori",
    10151: "fornitore",
    10152: "numero seriale",
    10153: "codice fornitore",
    10154: "codice azienda",
    10155: "id job interno massimo",
    10156: "id ottico interno",
    10157: "laboratorio salvato",
    10158: "dati laboratorio",
    10159: "tabella instradamento job dei fornitori",
    10160: "dati utente laboratorio",
    10161: "dispositivo utente",
    10162: "codice",
    10163: "indirizzo",
    10164: "lista prezzi",
    10165: "lista ottici",
    10166: "salva",
    10167: "cerca ottico",
    10168: "empty data source message",
    10169: "add",
    10170: "sei sicuro di eliminare questa riga?",
    10171: "righe per pagina",
    10172: "pagina precedente",
    10173: "pagina successiva",
    10174: "ottico dupplicato",
    10175: "ottico",
    10176: "capo",
    10177: "tecnico",
    10178: "ceo",
    10179: "ruolo",
    10180: "persona",
    10181: "cellulare",
    10182: "id persona",
    10183: "hub scope id",
    10184: "aperto",
    10185: "chiuso",
    10186: "wip",
    10187: "job",
    10188: "data",
    10189: "job recenti",
    10190: "nuovi job arrivati in laboratorio",
    10191: "job dell'anno",
    10192: "lista job dell'anno precedente",
    10193: "job della settimana",
    10194: "incremento job rispetto la scorsa settimana",
    10195: "job del giorno",
    10196: "lista job inseriti oggi",
    10197: "modifica profilo",
    10198: "completa il tuo profilo",
    10199: "aggiornato",
    10200: "data creazione",
    10201: "connessione avanzata ",
    10202: "clona",
    10203: "bit di dati", 
    10204: "modello salvato",
    10205: "tipo modello",
    10206: "nome modelllo",
    10207: "dati modelllo",
    10208: "id ottico",
    10209: "dati utente ottico",
    10210: "tracciatore",
    10211: "mola",
    10212: "bloccatore",
    10213: "tipo lenti",
    10214: "laboratori",
    10215: "hubs",
    10216: "utenti laboratorio",
    10217: "utenti ottici",
    10218: "modelli",
    10219: "scope types",
    10220: "sito",
    10221: "dati footer sito",
    10222: "esporta config",
    10223: "licenze",
    10224: "id",
    10225: "chiave licenza",
    10226: "rivenditore",
    10227: "data attivazione",
    10228: "attenzione",
    10229: "stai terminando le licenze",
    10230: "licenze totali",
    10231: "licenze attive",
    10232: "licenze non attive",
    10233: "utenti amministratori",
    10234: "utenti rivenditori",
    10235: "utente rivenditore",
    10236: "licenza",
    10237: "licenza salvata",
    10238: "licenza azienda",
    10239: "crea licenza",
    10240: "attiva licenza",
    10241: "disattiva licenza",
    10242: "attivato licenza",
    10243: "disattivato licenza",
    10244: "licenza creata",
    10245: "generatore licenze",
    10246: "numero licenze",
    10247: "licenze generate",
    10248: "azzera numeratori company",
    10249: "numeratore (externalJobId) aggiornato per tutte le company",
    10250: "per poter azzerare i numeratori è necessario inserire la password",
    10251: "azzera",
    10252: "azzera numeratori (externalJobId) company",
    10253: "da usare solamente a inizio anno",
    10254: "inserisci il numeratore (externalJobId) minimo",
    10255: "azzera numeratori",
    10256: "password errata",
    10257: "città - Provincia",
    10258: "report licenze",
    10259: "copia dati company",
    10260: "tipo licenza",
    10261: "moduli attivi",
    10262: "base",
    10263: "avanzata",
    10264: "license advanced gestione moduli",
    10265: "licenza advanced aggiornata correttamente",
    10266: "licenza advanced",
    10267: "moduli attivi licenza advanced",
    10268: "greco",
    10269: "all in one",
    10270: "num mesi utilizzo",
    10271: "costo per utilizzo",
    10272: "totale",
    10273: "report utilizzo connect ottici su base mensile dell'anno ",
    10274: "il costo mensile per singolo negozio è di: ",
    10275: "caricando aziende...",
    10276: "statistica utilizzo negozi",
    10277: "scope attiva",
    10278: "non modifica edgingData ottici",
    10279: "download setup:",
    10280: "download dati tabella",
    10281: "moduli advanced",
    10282: "solo forma",
    10283: "giorni prima della cancellazione",
    10284: "nome laboratorio",
    10285: "nome ottico",
    10286: "di cui",
    10287: "libere",
    10288: "forza log ReceiveData",
    10289: "ignora PinChanged",
    10290: "licenza eltima",
    10291: "elenco licenze eltima azienda",
    10292: "nessuna licenza trovata"
  },
}
export default it
